import prodConfig from "./auth_config.json";
import sitConfig from "./sit_auth_config.json";

export const getEnvironment = () => {
  return process.env.REACT_APP_ENV_NAME || "dev";
};

export function getConfig() {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.

  const config = getEnvironment() === "prod" ? prodConfig : sitConfig;
  const audience =
    config.audience && config.audience !== "YOUR_API_IDENTIFIER"
      ? config.audience
      : null;

  return {
    domain: config.domain,
    clientId: config.clientId,
    seamlessLoginPageClientId: config.seamlessLoginPageClientId,
    host: config.host,
    ...(audience ? { audience } : null),
  };
}
