import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";

import Home from "./views/Home";
import MobileSSOComponent from "./views/MobileSSO";
import MobileSSOUnavailable from "./views/MobileSSOUnavailable";
import history from "./utils/history";
import { isWebViewContext } from "./views/webViewHelper";

import "./App.css";

const App = () => {
  const { error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route
              path="/mobilesso"
              component={
                isWebViewContext() ? MobileSSOComponent : MobileSSOUnavailable
              }
            />
          </Switch>
        </Container>
      </div>
    </Router>
  );
};
export default App;
