import React from "react";

import Hero from "../components/Hero";

const MobileSSOUnavailable = () => (
  <>
    <Hero />
    <div>This page can only be accessed from the mobile application</div>
  </>
);

export default MobileSSOUnavailable;
