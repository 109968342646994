import loading from "../assets/Soloist_UI_LoadingBall_Vertical.gif";

const Loading = () => (
  <div className="loading-container">
    <img src={loading} alt="Loading" height="50px" />
    <h1 className="loading-text">
      We're just stretching our code legs, hang on
    </h1>
    <div className="box"></div>
  </div>
);

export default Loading;
